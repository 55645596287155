<template>
	<div class="wrap">
		<div class="flex justify-between">
			<!-- 搜索 -->
			<el-form :inline="true" v-model="searchForm">
				<el-form-item label="商品名称">
					<el-input placeholder="请输入商品名称" v-model="searchForm.name"></el-input>
				</el-form-item>
				<el-form-item label="商品分类">
					<el-select v-model="searchForm.classify" placeholder="请选择" clearable @change="pageChange(1)">
						<el-option v-for="item in classify" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="pageChange(1)">查询</el-button>
				</el-form-item>
			</el-form>
			<!-- 操作按钮 -->
			<el-form :inline="true">
				<el-form-item>
					<el-button @click="openDialog('in')">入库</el-button>
					<el-button @click="$router.push('/stock/detail')">出入库明细</el-button>
				</el-form-item>
			</el-form>
		</div>

		<el-table :data="tableData" v-loading="loading" max-height="9999" :row-class-name="tableRowClassName">
			<el-table-column type="index" width="50"></el-table-column>
			<el-table-column prop="code" label="商品编号" width="200"></el-table-column>
			<el-table-column prop="name" label="商品名称" width="300"></el-table-column>
			<el-table-column prop="sku_values" label="规格" width="150"></el-table-column>
			<el-table-column prop="current_price" label="单价" width="100"></el-table-column>
			<el-table-column prop="stock" label="库存"></el-table-column>
			<el-table-column prop="stoke_warning" label="预警数量"></el-table-column>
			<el-table-column fixed="right" label="操作" width="180">
				<template slot-scope="scope">
					<el-button @click="openDialog('in',scope.row)" size="mini">入库</el-button>
					<el-button @click="openDialog('out',scope.row)" size="mini">出库</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="text-center margin-top">
			<el-pagination @current-change="pageChange" :current-page="searchForm.page" :page-sizes="vuex_page_sizes"
				:page-size="searchForm.page_size" layout="total, sizes, prev, pager, next, jumper" :total="total"
				@size-change="pageSizeChange">
			</el-pagination>
		</div>

		<!-- 出入库 -->
		<el-dialog :title="dialog.type=='in'?'入库':'出库'" :visible.sync="dialog.visible" :append-to-body="true"
			width="800px" :close-on-click-modal="false">
			<el-form :model="dialog.form" label-width="100px">
				<el-form-item label="商品编号" v-if="dialog.type=='in'">
					<div class="flex">
						<el-input placeholder="商品编号,按回车键查询,可使用扫码枪" v-model="dialog.code"
							@keydown.native.enter="searchGoods()"></el-input>
						<el-button icon="el-icon-search" type="primary" @click="searchGoods()">查询</el-button>
					</div>
				</el-form-item>
				<el-form-item label="商品名称" v-if="dialog.product">
					<div class="flex align-center">
						<el-image v-if="dialog.product.cover" class="cover" :src="dialog.product.cover" fit="cover">
						</el-image>
						<div>{{dialog.product.name}}</div>
						<div class="margin-left text-gray" v-if="dialog.product.sku_values">
							[{{dialog.product.sku_values}}]</div>
					</div>
				</el-form-item>
				<el-form-item :label="dialog.type=='in'?'入库数量':'出库数量'">
					<el-input-number v-model="dialog.form.number" class="width-120" controls-position="right" :min="0">
					</el-input-number>
				</el-form-item>
				<el-form-item label="库存预警">
					<el-input-number v-model="dialog.form.stoke_warning" class="width-120" controls-position="right"
						:min="0">
					</el-input-number>
				</el-form-item>
				<el-form-item :label="dialog.type=='in'?'入库类型':'出库类型'">
					<el-radio-group v-model="dialog.form.type">
						<!-- 出入库类型： 1:进货 2：报溢 3：退货 4：销售 5：报损 6：自用 7：其他 -->
						<template v-if="dialog.type == 'in'">
							<el-radio :label="1">进货</el-radio>
							<el-radio :label="2">报溢</el-radio>
							<el-radio :label="3">退货</el-radio>
							<el-radio :label="7">其他</el-radio>
						</template>
						<template v-else>
							<el-radio :label="4">销售</el-radio>
							<el-radio :label="5">报损</el-radio>
							<el-radio :label="6">自用</el-radio>
							<el-radio :label="7">其他</el-radio>
						</template>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="备注">
					<el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4}" placeholder="请输入内容"
						v-model="dialog.form.remark">
					</el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialog.visible = false">取消</el-button>
				<el-button type="primary" @click="saveClose()" :loading="dialog.btnLoading">保存</el-button>
				<el-button type="primary" @click="save()" :loading="dialog.btnLoading" v-if="dialog.type=='in'">保存并继续
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				loading: false,
				tableData: [],
				total: 0,
				classify: [], // 分类列表
				searchForm: {
					name: '', // 名称
					classify: '', // 分类
					page: 1,
					page_size: 10
				},
				dialog: {
					btnLoading: false,
					type: 'out', // in 入库， out 出库
					visible: false,
					product: {}, // 商品信息
					code: '', // 用户输入的商品编号
					form: {
						id: '',
						stoke_warning: 0, // 库存预警
						code: '', // 商品编号
						number: 0, // 出入库数量
						goods_type: 1, // 1 品牌商品   2 自营商品
						operation_type: 1, // 1 入库  2 出库
						type: 1, // 出入库类型： 1:进货 2：报溢 3：退货 4：销售 5：报损 6：自用 7：其他
						remark: '' // 备注
					}
				}
			};
		},
		mounted() {
			this.search()
			// 获取类型
			this.$api.classify.getBrandClassify(this.$api.classify.GOODS).then(res => {
				this.classify = res.data
			})
		},
		methods: {

			search() {
				this.loading = true;
				this.$api.stock.getBrandGoods({
					page: this.searchForm.page,
					page_size: this.searchForm.page_size,
					name: this.searchForm.name,
					classify: this.searchForm.classify && [this.searchForm.classify]
				}).then(res => {
					this.tableData = res.data.data;
					this.total = res.data.count;
					this.loading = false;
				}).catch(() => {
					this.loading = false;
				})
			},
			pageSizeChange(size) {
				this.searchForm.page_size = size;
				this.search()
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.search()
			},
			// 打开出入库弹框
			openDialog(type, item) {
				if (item) {
					this.dialog.form.id = item.id;
					this.dialog.product = item;
					this.dialog.code = item.code;
					this.dialog.form.code = item.code;
					this.dialog.form.stoke_warning = item.stoke_warning;
					this.dialog.form.goods_type = 1; // 1 品牌商品   2 自营商品
				} else {
					this.dialog.product = {};
				}
				this.dialog.type = type;
				this.dialog.form.operation_type = type == 'in' ? 1 : 2;
				this.dialog.form.type = type == 'in' ? 1 : 4;
				// 打开弹框
				this.dialog.visible = true;
			},
			// 查询商品
			searchGoods() {
				this.$api.goods.getGoodsByCode({
					code: this.dialog.code
				}).then(res => {
					if (res.code == 200) {
						this.dialog.form.id = res.data.specs_id;
						this.dialog.form.code = res.data.code;
						this.dialog.form.goods_type = res.data.type;
						this.dialog.product = res.data;
					} else {
						this.dialog.code = '';
						this.$message.error(res.msg)
					}
				})
			},
			save() {
				if (!this.dialog.form.id) {
					this.$message.error('请选择商品')
					return;
				}
				this.dialog.btnLoading = true;
				return this.$api.stock.update(this.dialog.form).then(res => {
					this.dialog.btnLoading = false;
					if (res.code == 200) {
						this.$message.success('操作成功');
						this.dialog.form.id = '';
						this.dialog.code = '';
						this.dialog.form.code = '';
						this.dialog.product = {};
						this.search()
						return Promise.resolve('success')
					} else {
						this.$message.error(res.msg)
						return Promise.reject(res.msg)
					}
				}).catch(err => {
					this.$message.error(err)
					this.dialog.btnLoading = false;
				})
			},
			saveClose() {
				this.save().then(() => {
					this.dialog.visible = false;
				})
			},
			tableRowClassName(data) {
				// console.log(data.row)
				if (data.row.stock < data.row.stoke_warning) {
					return 'warning-row';
				}
				return '';
			}
		},
	};
</script>

<style scoped lang="scss">
	.cover {
		width: 40px;
		height: 40px;
		border-radius: 5px;
		overflow: hidden;
		margin-right: 10px;
	}

	.el-table /deep/ .warning-row {
		// background: oldlace;
		color: red;
	}
</style>
